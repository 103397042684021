   
        .custom-cross {
          position: relative;
          width: 20px;
          height: 20px;
        }.allcolors-canvasid {
          display: grid
      ;
          grid-template-columns: auto auto auto auto;
          column-gap: 23px;
      }

        .custom-cross::before,
        .custom-cross::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 2px;
          background-color: red;
          transform-origin: center;
        }

        .custom-cross::before {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        .custom-cross::after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
     

        .page-title-box .page-title {
          font-size: 2rem;
          margin: 0;
          line-height: 75px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-transform: uppercase;
          font-weight: 700;
      }
      /* .table.table-borderless.table-hover.table-nowrap.table-centered.m-0 {
          display: grid;
          grid-template-columns: auto auto auto;
      } */
      .image-clipartcategory {
        width: 100px;
        height: 100px;
    }
    
      .abdu-cls {
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        width: 100%;
        justify-content: space-between;
        row-gap: 30px;
    }
    .inner-clipart {
      text-align: center;
  }

.img-setting {

  display: grid;
  /* justify-content: space-between; */
  grid-template-columns: auto auto;
  row-gap: 0;

    
}


.t-row {
border: 1px solid;
}

.size-container {
  display: grid;
  /* flex-wrap: wrap; */
  /* gap: 10px; */
  grid-template-columns: auto auto auto;
  margin-bottom: 15px;
  gap: 2px;
}

.size-quantity-box {
  padding: 0;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  text-align: center;
  min-width: auto;
  font-size: 12px;
}


.product-box {
  height: 100% !important;
}

.cls {
  margin-bottom: 15px !important;
}

.product-card {
  height: 100% !important;
}
table.table-main-cls {
  border: 1px solid;
  width: 100%;
}
table.table-main-cls td {
  border: 1px solid;
 
}

.masking {
  display: grid;
  grid-template-columns: auto auto;
}


.colorss {
  display: grid
  ;
      grid-template-columns: auto auto auto auto auto auto auto auto;
      width: 100%;
      gap: 23px;
}

.namess {
  text-wrap: auto;
}

.cell {
  display: grid;
  grid-template-columns: auto auto;
}

.highlighted-row {
  background-color: #75c6d2 !important;
  color: white !important;
}

.rejected-row {
  background-color: #ed7e3c !important;
  color: white !important;
}

