div#work-orders-container {
    /* width: 1200px; */
    max-width: 100%;
    margin: 0 auto;
}

.purchase-order {
  
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
  }
  .main-purchase-order{

    border: 1px solid rgb(204, 204, 204);
  }
  
  .header {
    display: flex
;
    justify-content: end;
    margin-bottom: 56px;
    margin-top: 20px;
}
  
  .logo-placeholder {
    width: 150px;
    height: 50px;
    /* background: #f0f0f0; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .title h1 {
    margin: 0;
    color: #2a3b8d;
  }
  
  .info-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .info-box {
    width: 30%;
  }
  
  .shipping-section,
  .items-section {
    margin-bottom: 20px;
  }
  
  .shipping-table,
  .items-table,
  .total-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .shipping-table th,
  .items-table th,
  .total-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .total-table {
    margin-top: 10px;
  }
  
  .total-row {
    font-weight: bold;
  }
  
  .footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .demo-link {
    margin-top: 10px;
    text-align: center;
  }
  
  .demo-link a {
    background-color: #27c2a0;
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
  }


  .image-previews {
    margin-bottom: 20px;
  }
  
  .preview-images {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: start;
  }
  
  .preview-image {
    text-align: center;
  }
  
  .preview-image p {
    margin: 5px 0;
    font-size: 14px;
    font-weight: bold;
  }
  
  .preview-image img {
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .items-table tr:last-of-type {
    font-weight: bold;
    background-color: #f2f2f2; /* Optional: Light gray background */
  }
  
  .items-table td {
    padding: 8px;
    border: 1px solid #ddd; /* Add borders for table cells */
  }
  
  .items-table td[colspan="5"] {
    text-align: right;
  }
  
  .items-table td[colspan="2"] {
    text-align: right;
  }