.main-color-image {

    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}
tr.main-color-image td {
    border: 1px solid #cccccc;
    padding: 30px 0px;
}
/* .colorbox-workorder{
    border: 1px solid gray;

} */
.product-name-work{
    text-align: center;
    margin-bottom: 30px  !important;
}
div#work-orders-container {


    /* width: 1200px; */
    max-width: 100%;
    margin: 0 auto;
}
td.hover-colorss {
    border: 0 !important;
}
.image-workorder{
    padding: 0 !important;
}
.invoice-header {
    display: flex
;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 2px solid #ddd; */
    padding-bottom: 10px;
}
.logo-invoice {
    width: 120px;
}
.company-details {
    text-align: right;
}
.company-details p img {
    margin-left: 12px;
}
p{
    margin: 0 !important;
}
.workorder-title {
    text-align: center;
    margin: 15px 0;
    font-size: 32px;
    font-weight: bold;
   
}
.disclaimer {
    padding-bottom: 20px;
    font-size: 10px;
}
.invoice-footer .footer-icon {
    text-align: center;
    font-size: 12px;
    color: #777;
    background: #1f1f2b;
    padding: 5px 0px;
}
.invoice-footer {
    margin-top: 170px;
    text-align: center;
 
    width: 100%;
}