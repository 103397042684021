//
// dropzone.scss
//
.tool-apprealnone{
    display: none !important;
}
.tool-apprealborder {
    /* border: 1px solid; */
    background: #00c9a7;
    margin-bottom: 12px;
    border-radius: 12px;
    padding: 12px 0px;
}

.tool-apprealbordertwo {
    /* border: 1px solid; */
    background: #0081cf85;
    margin-bottom: 12px;
    border-radius: 12px;
    padding: 12px 0px;
}
.row.tool-apprealbordertwo label.form-label {
    color: white !important;
}
.inputguideline{
width: 23% !important;

}

.tool-apprealborder.row label.form-label {
    color: white;
}
.row.tool-apprealbordertwo h6 {
    font-size: 16px;
    text-transform: uppercase;
    color: white;
}
.dropzone {
    border: 2px dashed $input-border-color;
    background: $input-bg;
    border-radius: 6px;
    cursor: pointer;
    min-height: 150px;
    padding: 20px;

    .dz-message {
        text-align: center;
        margin: 2rem 0;
    }
    &.dz-started {
        .dz-message {
            display: none;
        }
    }
}
